import { forwardRef } from 'react';
import { Dialog as ChakraDialog, Portal } from '@chakra-ui/react';
import { CloseButton } from './close-button';

interface DialogContentProps extends ChakraDialog.ContentProps {
	portalled?: boolean;
	portalRef?: React.RefObject<HTMLElement>;
	backdrop?: boolean;
}
// eslint-disable-next-line react/display-name
export const DialogContent = forwardRef<HTMLDivElement, DialogContentProps>((props, ref) => {
	const { children, portalled = true, portalRef, backdrop = true, ...rest } = props;

	return (
		<Portal disabled={!portalled} container={portalRef}>
			{backdrop && <ChakraDialog.Backdrop />}
			<ChakraDialog.Positioner>
				<ChakraDialog.Content ref={ref} {...rest} asChild={false}>
					{children}
				</ChakraDialog.Content>
			</ChakraDialog.Positioner>
		</Portal>
	);
});
// eslint-disable-next-line react/display-name
export const DialogCloseTrigger = forwardRef<HTMLButtonElement, ChakraDialog.CloseTriggerProps>((props, ref) => (
	<ChakraDialog.CloseTrigger position="absolute" top="3" insetEnd="4" {...props} asChild>
		<CloseButton size="md" ref={ref}>
			{props.children}
		</CloseButton>
	</ChakraDialog.CloseTrigger>
));

export const DialogRoot = ChakraDialog.Root;
export const DialogFooter = ChakraDialog.Footer;
export const DialogHeader = ChakraDialog.Header;
export const DialogBody = ChakraDialog.Body;
export const DialogBackdrop = ChakraDialog.Backdrop;
export const DialogTitle = ChakraDialog.Title;
export const DialogDescription = ChakraDialog.Description;
export const DialogTrigger = ChakraDialog.Trigger;
export const DialogActionTrigger = ChakraDialog.ActionTrigger;
