import { forwardRef } from 'react';
import { Accordion, AccordionItemContentProps, HStack } from '@chakra-ui/react';
import { FiChevronDown } from '@shared/icons';

interface AccordionItemTriggerProps extends Accordion.ItemTriggerProps {
	indicatorPlacement?: 'start' | 'end';
}

export const AccordionItemTrigger = forwardRef<HTMLButtonElement, AccordionItemTriggerProps>((props, ref) => {
	const { children, indicatorPlacement = 'end', ...rest } = props;
	return (
		<Accordion.ItemTrigger {...rest} ref={ref}>
			{indicatorPlacement === 'start' && (
				<Accordion.ItemIndicator rotate={{ base: '-90deg', _open: '0deg' }}>
					<FiChevronDown />
				</Accordion.ItemIndicator>
			)}
			<HStack gap="4" flex="1" textAlign="start" width="full">
				{children}
			</HStack>
			{indicatorPlacement === 'end' && (
				<Accordion.ItemIndicator>
					<FiChevronDown />
				</Accordion.ItemIndicator>
			)}
		</Accordion.ItemTrigger>
	);
});
AccordionItemTrigger.displayName = 'AccordionItemTrigger';

export const AccordionItemContent = forwardRef<HTMLDivElement, AccordionItemContentProps>((props, ref) => (
	<Accordion.ItemContent>
		<Accordion.ItemBody {...props} ref={ref} />
	</Accordion.ItemContent>
));
AccordionItemContent.displayName = 'AccordionItemContent';

export const AccordionRoot = Accordion.Root;
export const AccordionItem = Accordion.Item;
