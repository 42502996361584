import { Flex, Span, Text } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import { GroupedNotificationData } from '@shared/zod-schemas';
import { useUpdateInvitationById } from '@shared/client-api';
import { errorToast, successToast } from '@/utils/toast';
import { Button } from '@/components/ui/button';
import { useInvalidateNotificationsInvitations } from '@/hooks/useInvalidateNotificationsInvitations';
import { formatDateTime } from '../form/utils';

export default function InvitationContent({ notification }: { notification: GroupedNotificationData }) {
	const n = useTranslations('Notifications');
	const r = useTranslations('InvitationTable');

	const { mutateAsync, isPending } = useUpdateInvitationById();

	const invalidateQueries = useInvalidateNotificationsInvitations();

	const handleAction = async (state: 'approved' | 'rejected', successMessage: string, errorMessage: string) => {
		try {
			await mutateAsync({ id: notification.id, data: { state } });
			successToast(successMessage);
			invalidateQueries(true);
		} catch {
			errorToast(errorMessage);
		}
	};

	return (
		<Flex gap={3} flexDir="column">
			<Flex gap={1} flexDir="column">
				<Text fontWeight="semibold" color="fg.normalContrast">
					{notification.senderName} <Span fontWeight="normal">{n('youWereInvited')}</Span>{' '}
					{notification.receiverName}
				</Text>

				<Text color="fg.mediumContrast">
					{notification.createdAt && formatDateTime(notification.createdAt)}
				</Text>
			</Flex>

			<Flex gap={3}>
				<Button
					h={8}
					colorPalette="green"
					size="sm"
					onClick={() => handleAction('approved', r('acceptedInvite'), r('error'))}
					loading={isPending}
				>
					{r('approvedButton')}
				</Button>
				<Button
					h={8}
					colorPalette="red"
					size="sm"
					variant="subtle"
					onClick={() => handleAction('rejected', r('editMessage'), r('rejectedInvite'))}
					loading={isPending}
				>
					{r('rejectedButton')}
				</Button>
			</Flex>
		</Flex>
	);
}
