import React, { useRef, useState } from 'react';
import { useParams } from 'next/navigation';
import { BoxProps, ButtonGroup, Flex, HStack, Separator, Stack, Text } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import { OwnerWithRoles } from '@shared/zod-schemas';
import { FiChevronDown, FiSettings, IoAddOutline, HiBuildingOffice, FiChevronRight, FiCheck } from '@shared/icons';
import { PopoverBody, PopoverContent, PopoverRoot, PopoverTrigger } from '@/components/ui/popover';
import { Button } from '@/components/ui/button';
import { useRouter } from '@/i18n/routing';

interface SidebarCompaniesPopoverProps extends BoxProps {
	owners: OwnerWithRoles[];
	onClose: () => void;
}

export default function SidebarCompaniesPopover({ owners, onClose }: SidebarCompaniesPopoverProps) {
	const router = useRouter();
	const [open, setOpen] = useState(false);
	const ref = useRef<HTMLButtonElement>(null);
	const params = useParams();

	const actualOwner = owners.find((owner) => owner.id === params.ownerId);

	const n = useTranslations('Nav');

	return (
		<Flex alignItems="center" my={2} mx={4}>
			<PopoverRoot
				initialFocusEl={() => ref.current}
				open={open}
				onOpenChange={(e) => setOpen(e.open)}
				positioning={{ placement: 'top-end' }}
			>
				<PopoverTrigger asChild>
					<Button
						px={3}
						variant="ghost"
						width="full"
						height="44px"
						bg={open ? 'bg.neutralAccentAlpha' : 'bg.card'}
						_hover={{ bg: 'bg.neutralAccentAlpha' }}
						justifyContent="space-between"
					>
						<HStack>
							<HiBuildingOffice size="20px" />
							<Text
								fontWeight="semibold"
								width="full"
								maxWidth="9rem"
								textAlign="left"
								lineHeight="short"
								fontSize={16}
								lineClamp={2}
							>
								{actualOwner?.companyName}
							</Text>
						</HStack>
						<FiChevronDown color="var(--chakra-colors-fg-low-contrast)" />
					</Button>
				</PopoverTrigger>
				<PopoverContent width={{ sm: 'lg', md: 'xs' }}>
					<PopoverBody border={0} p={3}>
						<HStack px={2} gap={3} color="fg.mediumContrast">
							<HiBuildingOffice size="32px" />
							<Stack gap={1}>
								<Text
									width="full"
									truncate
									maxWidth={40}
									fontSize={16}
									fontWeight="semibold"
									lineHeight="short"
								>
									{actualOwner?.companyName}
								</Text>
								<Text fontSize={14} lineHeight="short">
									{actualOwner?.userRole}
								</Text>
							</Stack>
						</HStack>
						<Stack mt={2}>
							<Button
								justifyContent="flex-start"
								size="sm"
								variant="ghost"
								_hover={{ bg: 'bg.neutralAccentAlpha' }}
								px={2}
								onClick={() => {
									router.push(`/app/owners/${actualOwner?.id}/settings`);
									onClose();
									setOpen(false);
								}}
							>
								<HStack>
									<FiSettings />
									<Text fontSize={14} fontWeight="normal">
										{n('availableCompaniesDropdown.companySettings')}
									</Text>
								</HStack>
							</Button>
						</Stack>
						{owners.length > 0 && (
							<>
								<Separator my={3} />
								<Text mb={2} pl={2} fontWeight="medium" color="fg.mediumContrast" lineHeight="short">
									{n('availableCompaniesDropdown.switchCompany')}
								</Text>
								<ButtonGroup width="full" orientation="vertical" gap={1}>
									{owners.map((owner) => (
										<Button
											key={owner?.id}
											width="full"
											borderRadius="lg"
											background={
												actualOwner?.id === owner?.id ? 'bg.primaryAccentAlpha' : 'transparent'
											}
											color={
												actualOwner?.id === owner?.id ? 'fg.primaryText' : 'fg.mediumContrast'
											}
											fontWeight={actualOwner?.id === owner?.id ? '500' : '400'}
											_hover={{
												bg:
													actualOwner?.id === owner?.id
														? 'bg.primaryAccentAlpha'
														: 'bg.neutralAccentAlpha',
											}}
											onClick={() => {
												router.push(`/app/owners/${owner.id}`);
												onClose();
												setOpen(false);
											}}
											px={2}
										>
											<HStack justifyContent="space-between" width="full">
												<Flex justifyContent="start" alignItems="center">
													<HiBuildingOffice size="20px" />
													<Text truncate maxWidth={40} ml={2}>
														{owner?.companyName}
													</Text>
												</Flex>
												{actualOwner?.id === owner?.id ? (
													<FiCheck transform="scale(0.8)" /> // refactor this to be smaller
												) : (
													<FiChevronRight color="var(--chakra-colors-fg-low-contrast)" />
												)}
											</HStack>
										</Button>
									))}
									<Button
										width="full"
										pt={3}
										pb={3}
										px={2}
										bg="transparent"
										borderRadius="lg"
										_hover={{ bg: 'bg.neutralAccentAlpha' }}
										justifyContent="start"
										onClick={() => {
											router.push('/app/owners/new');
											onClose();
											setOpen(false);
										}}
									>
										<HStack>
											<IoAddOutline />
											<Text fontWeight="normal" color="fg.mediumContrast" fontSize={14}>
												{n('availableCompaniesDropdown.addNewCompany')}
											</Text>
										</HStack>
									</Button>
								</ButtonGroup>
							</>
						)}
					</PopoverBody>
				</PopoverContent>
			</PopoverRoot>
		</Flex>
	);
}
