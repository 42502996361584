'use client';

import { useParams } from 'next/navigation';
import Link from 'next/link';
import Image from 'next/image';
import { Box, BoxProps, Separator, Flex, FlexProps, HStack, Stack, useDisclosure } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';

import { OwnerWithRoles } from '@shared/zod-schemas';
import { BiCog, BiQr, MdDashboard, FiUsers, FiMenu, BiBriefcase } from '@shared/icons';
import { usePathname } from '@/i18n/routing';
import { useIsAdminOrSuperAdmin } from '@/hooks/useIsAdminOrSuperAdmin';
import NavButton from '@/components/nav/NavButton';
import { Button } from '@/components/ui/button';

import { ColorModeButton, useColorMode } from '@/components/ui/color-mode';
import { CloseButton } from '@/components/ui/close-button';
import { DrawerContent, DrawerRoot } from '@/components/ui/drawer';
import { NotificationPopover } from '../notification/NotificationPopover';
import NavUserPopover from './NavUserPopover';

import LogoTaskgripLight from '../../images/logoTaskgripLight.svg';
import LogoTaskgrip from '../../images/logoTaskgrip.svg';
import Command from '../command/Command';
import Breadcrumb from './Breadcrumb';
import Submenu from './Submenu';
import { SubsTrialBox } from '../subscription/SubsBox';
import SidebarCompaniesPopover from './SidebarCompaniesPopover';
import { ExpiredSubsTrialBox } from '../subscription/ExpiredSubsBox';

interface MobileProps extends FlexProps {
	onOpen: () => void;
	owners: OwnerWithRoles[];
	onClose: () => void;
}

interface SidebarProps extends BoxProps {
	onClose: () => void;
	owners: OwnerWithRoles[];
}

function SidebarContent({ onClose, owners, ...rest }: SidebarProps) {
	const n = useTranslations('Nav');
	const path = usePathname();
	const params = useParams();

	const isDarkMode = useColorMode().colorMode === 'dark';

	const isAdmin = useIsAdminOrSuperAdmin();

	const EmployeeSubmenu = [
		{ text: n('employeesOverview'), path: '/overview' },
		{ text: n('employeesAttendance'), path: '' },
		{ text: n('employeesManagement'), path: '/employee-management' },
		{ text: n('employeesMap'), path: '/employee-map' },
	];

	const actualOwner = owners.find((owner) => owner.id === params.ownerId);

	const hasTrialDateInFuture = actualOwner?.trialEndDate && new Date(actualOwner.trialEndDate) > new Date();
	const hasTrial = !actualOwner?.stripeSubscriptionId && hasTrialDateInFuture;
	const hasActiveSubscription =
		actualOwner?.subscriptionPeriodEnd && new Date(actualOwner.subscriptionPeriodEnd) > new Date();

	return (
		<Box
			transition="1s ease"
			bg="bg.card"
			boxShadow="sm"
			w={{ base: 'full', md: 60 }}
			pos="fixed"
			h="100dvh"
			pt={{ base: 0, md: 16 }}
			justifyContent="space-around"
			display="flex"
			flexDir="column"
			{...rest}
		>
			<Flex
				display={{ base: 'flex', md: 'none' }}
				pr={{ base: 4, md: 7 }}
				justifyContent="space-between"
				alignItems="center"
				width="auto"
				ml={6}
				py={{ base: 4, md: 0 }}
			>
				<Link href="/app" onClick={onClose}>
					{isDarkMode ? (
						<Image src={LogoTaskgripLight} alt="taskgrip" height={30} />
					) : (
						<Image src={LogoTaskgrip} alt="taskgrip" height={30} />
					)}
				</Link>
				<CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
			</Flex>

			<Flex flexDirection="column" flex={1} overflowY="auto">
				{owners.length !== 0 && <SidebarCompaniesPopover owners={owners} onClose={onClose} />}
				{params.ownerId && (
					<Flex flexDirection="column">
						<Separator />
						<Stack gap={2} pt={5} px={4} h="full">
							<NavButton
								text={n('dashboard')}
								icon={<MdDashboard size="20px" />}
								onClick={() => {
									onClose();
								}}
								href={`/app/owners/${params.ownerId}`}
								isActive={path === `/app/owners/${params.ownerId}`}
							/>

							{isAdmin && (
								<Submenu ownerId={params.ownerId as string} onClose={onClose} items={EmployeeSubmenu} />
							)}

							<NavButton
								text={n('jobs')}
								icon={<BiBriefcase size="20px" />}
								onClick={() => {
									onClose();
								}}
								href={`/app/owners/${params.ownerId}/jobs`}
								isActive={path === `/app/owners/${params.ownerId}/jobs`}
							/>

							{isAdmin && (
								<>
									{params.ownerId && (
										<NavButton
											text={n('customers')}
											icon={<FiUsers size="20px" />}
											href={`/app/owners/${params.ownerId}/customers`}
											isActive={path === `/app/owners/${params.ownerId}/customers`}
										/>
									)}
									<NavButton
										text={n('settings')}
										icon={<BiCog size="20px" />}
										onClick={() => {
											onClose();
										}}
										href={`/app/owners/${params.ownerId}/settings`}
										isActive={path === `/app/owners/${params.ownerId}/settings`}
									/>

									<NavButton
										text={n('qr')}
										icon={<BiQr size="20px" />}
										onClick={() => {
											onClose();
										}}
										href={`/app/owners/${params.ownerId}/qr`}
										isActive={path === `/app/owners/${params.ownerId}/qr`}
									/>
								</>
							)}
						</Stack>
					</Flex>
				)}
			</Flex>

			{hasTrial && (
				<Box pb={5} p={4} borderTopWidth="1px">
					<SubsTrialBox ownerId={actualOwner?.id} freeTrialEndDate={actualOwner?.trialEndDate} />
				</Box>
			)}
			{!hasTrial && !hasActiveSubscription && (
				<Box pb={5} p={4} borderTopWidth="1px">
					<ExpiredSubsTrialBox ownerId={actualOwner?.id} />
				</Box>
			)}
		</Box>
	);
}

function MobileNav({ onOpen, onClose, ...rest }: MobileProps) {
	const params = useParams();

	const isDarkMode = useColorMode().colorMode === 'dark';

	return (
		<Flex
			ml={{ base: 0, md: params.ownerId ? 0 : 0 }}
			pl={{ base: 4, md: 6 }}
			pr={{ base: 4, md: 4 }}
			height="16"
			alignItems="center"
			bg="bg.card"
			boxShadow={{ base: 'none', md: 'sm' }}
			justifyContent={{ base: params.ownerId ? 'space-between' : 'flex-end', md: 'space-between' }}
			pos="fixed"
			width={{ base: 'full', md: '100%' }}
			zIndex={3}
			{...rest}
		>
			{params.ownerId && (
				<Button
					display={{ base: 'flex', md: 'none' }}
					onClick={onOpen}
					variant="outline"
					aria-label="open menu"
					width="40px"
				>
					<FiMenu />
				</Button>
			)}

			<Flex alignItems="center" display={{ base: 'none', md: 'flex' }} gap={{ base: '64px', xl: '136px' }}>
				<Link href="/app" onClick={onClose}>
					{isDarkMode ? (
						<Image src={LogoTaskgripLight} alt="taskgrip" height={30} />
					) : (
						<Image src={LogoTaskgrip} alt="taskgrip" height={30} />
					)}
				</Link>

				<Breadcrumb />
			</Flex>

			<HStack gap={4} height="64px" alignItems="center">
				<Command />
				<NotificationPopover />
				<ColorModeButton />
				<NavUserPopover />
			</HStack>
		</Flex>
	);
}

function SidebarWithHeader({ children, owners }: { children: React.ReactNode; owners: OwnerWithRoles[] }) {
	const { open, onOpen, onClose } = useDisclosure();
	const params = useParams();

	return (
		<Box minH="100dvh" bg="bg.baseMain">
			{params.ownerId && (
				<SidebarContent
					onClose={onClose}
					display={{ base: 'none', md: 'flex' }}
					flexDir="column"
					owners={owners}
				/>
			)}
			<DrawerRoot open={open} onExitComplete={onClose} size="full">
				<DrawerContent>
					<SidebarContent onClose={onClose} owners={owners} />
				</DrawerContent>
			</DrawerRoot>

			<MobileNav onOpen={onOpen} owners={owners} onClose={onClose} />

			<Box
				ml={{ base: 0, md: params.ownerId ? 60 : 0 }}
				pt="118px"
				pl={{ base: 5, md: 14 }}
				pr={{ base: 5, md: 14 }}
				pb={{ base: 5, md: 14 }}
			>
				{children}
			</Box>
		</Box>
	);
}

export default SidebarWithHeader;
