import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { getGetGroupedNotificationsQueryKey, getGetInvitationCardQueryKey } from '@shared/client-api';
import { useRouter } from '@/i18n/routing';

export const useInvalidateNotificationsInvitations = () => {
	const router = useRouter();
	const queryClient = useQueryClient();

	return useCallback(
		(refresh = false) => {
			queryClient.resetQueries({ queryKey: getGetGroupedNotificationsQueryKey() });
			queryClient.resetQueries({ queryKey: getGetInvitationCardQueryKey() });
			if (refresh) router.refresh();
		},
		[queryClient, router],
	);
};
