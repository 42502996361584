import { Mark } from '@chakra-ui/react';

export function highlightText(text: string, searchTerm: string) {
	if (!searchTerm) return text;

	const escapedSearchTerm = searchTerm.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
	const regex = new RegExp(`(${escapedSearchTerm})`, 'gi');

	return text.split(regex).map((part, index) =>
		part.toLowerCase() === searchTerm.toLowerCase() ? (
			// eslint-disable-next-line
			<Mark key={`${part}-${index}`} variant="solid">
				{part}
			</Mark>
		) : (
			part
		),
	);
}
