import React from 'react';
import { useParams } from 'next/navigation';
import { useTranslations } from 'next-intl';
import { usePathname, useRouter } from '@/i18n/routing';
import { BreadcrumbCurrentLink, BreadcrumbLink, BreadcrumbRoot } from '@/components/ui/breadcrumb';

export default function Breadcrumb({ onClose }: { onClose?: () => void }) {
	const router = useRouter();
	const paths = usePathname();
	const { ownerId } = useParams();

	const g = useTranslations('General');
	const t = useTranslations('Nav');

	const [breadcrumbPaths, setBreadcrumbPaths] = React.useState<{ slug: string; path: string; isLast: boolean }[]>([]);

	React.useEffect(() => {
		const splitPaths = paths.split('/').filter((path) => path !== '');

		const breadcrumbPaths = splitPaths
			.map((path, index, arr) => {
				const isLast = index === splitPaths.length - 1;

				if (!arr[3] && isLast) {
					return { slug: t('dashboard'), path: paths, isLast };
				}

				if (arr[3] && arr[3] === path) {
					return { slug: t(path), path: `/app/owners/${ownerId}/${path}`, isLast };
				}

				if (arr[4] === path && path === 'new') {
					return { slug: g('add'), path: `/app/owners/${ownerId}/${arr[3]}/${path}`, isLast };
				}

				if (arr[4] && arr[4] === path) {
					return {
						slug: t(`breadcrumb.${arr[3]}`),
						path: `/app/owners/${ownerId}/${arr[3]}/${path}`,
						isLast,
					};
				}

				if (arr[5] && arr[5] === path) {
					return {
						slug: t(`breadcrumb.${path}`),
						path: `/app/owners/${ownerId}/${arr[3]}/${arr[4]}/${path}`,
						isLast,
					};
				}

				return { slug: '', path: paths, isLast };
			})
			.filter((path) => path.slug !== '');

		setBreadcrumbPaths(breadcrumbPaths);
	}, [paths, ownerId, t, g]);

	return (
		<BreadcrumbRoot>
			{ownerId &&
				breadcrumbPaths.map((path, index) => (
					<React.Fragment key={`${index + 1}`}>
						{path.isLast ? (
							<BreadcrumbCurrentLink fontWeight="semibold">{path.slug}</BreadcrumbCurrentLink>
						) : (
							<BreadcrumbLink
								href={path.path}
								onClick={() => {
									if (onClose) onClose();
									router.push(path.path);
								}}
							>
								{path.slug}
							</BreadcrumbLink>
						)}
					</React.Fragment>
				))}
		</BreadcrumbRoot>
	);
}
