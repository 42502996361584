import React from 'react';
import { Link } from '@chakra-ui/react';
import { Button } from '@/components/ui/button';

export default function NavButton({
	text,
	icon,
	onClick,
	isActive = false,
	hoverColor = 'bg.neutralAccentAlpha',
	borderRadius = 'lg',
	href,
}: {
	text: string;
	icon?: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
	onClick?: () => void;
	isActive?: boolean;
	hoverColor?: string;
	borderRadius?: string;
	href?: string;
}) {
	return href ? (
		<Link
			href={href}
			p={3}
			textStyle="md"
			lineHeight="short"
			color={isActive ? 'fg.primaryText' : 'fg.mediumContrast'}
			bg={isActive ? 'bg.primaryAccentAlpha' : 'transparent'}
			w="100%"
			justifyContent="flex-start"
			transition="all 0.3s ease"
			_hover={{
				bgColor: isActive ? 'bg.primaryAccentAlpha' : hoverColor,
				textDecoration: 'none',
			}}
			onClick={onClick}
			fontWeight={isActive ? 500 : 400}
			borderRadius={borderRadius}
		>
			{icon}
			{text}
		</Link>
	) : (
		<Button
			onClick={onClick}
			p={3}
			textStyle="md"
			lineHeight="tight"
			color={isActive ? 'fg.primaryText' : 'fg.mediumContrast'}
			bg={isActive ? 'bg.primaryAccentAlpha' : 'transparent'}
			w="100%"
			justifyContent="flex-start"
			transition="all 0.3s ease"
			fontWeight={isActive ? 500 : 400}
			borderRadius={borderRadius}
			alignItems="center"
			_hover={{
				bgColor: isActive ? 'bg.primaryAccentAlpha' : hoverColor,
			}}
		>
			{icon}
			{text}
		</Button>
	);
}
