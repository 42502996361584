'use client';

import { Box, Flex } from '@chakra-ui/react';
import { GroupedNotification } from '@shared/zod-schemas';
import NotificationItem from './NotificationItem';

export default function Notifications({ groupedNotifications }: { groupedNotifications: GroupedNotification }) {
	return (
		<>
			{groupedNotifications?.newer?.length !== 0 && (
				<Box>
					<Flex flexDirection="column">
						{groupedNotifications.newer.map((notification, index, arr) => (
							<NotificationItem
								key={notification.id}
								notification={notification}
								isLast={index === arr.length - 1}
							/>
						))}
					</Flex>
				</Box>
			)}
			{groupedNotifications?.older?.length !== 0 && (
				<Box w="full">
					<Flex flexDirection="column" mb={1}>
						{groupedNotifications.older.map((notification, index, arr) => (
							<NotificationItem
								key={notification.id}
								notification={notification}
								isLast={index === arr.length - 1}
							/>
						))}
					</Flex>
				</Box>
			)}
		</>
	);
}
