'use client';

import Link from 'next/link';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';

export function ExpiredSubsTrialBox(props: { ownerId: string | undefined }) {
	const b = useTranslations('Subscription.ExpiredBox');
	const { ownerId } = props;
	return (
		<Link href={`/app/owners/${ownerId}/settings`}>
			<Box bg="bg.redAccentAlpha" borderRadius="8px" p={0} borderColor="fg.border" borderWidth="1px">
				<Flex flexDir="column" justifyContent="center" alignItems="center">
					<Flex p={3} pb={2} flexDir="column" gap={1} width="100%">
						<Text
							color="fg.redText"
							fontSize="14px"
							fontWeight="semibold"
							textTransform="uppercase"
							lineHeight="short"
							fontFamily="Gabarito"
						>
							{b('title')}
						</Text>

						<Text color="fg.redText" textStyle="xs" textDecor="underline">
							{b('btnLabel')}
						</Text>
					</Flex>
				</Flex>
			</Box>
		</Link>
	);
}
