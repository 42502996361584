import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import { FiUsers } from '@shared/icons';
import { usePathname, useRouter } from '@/i18n/routing';

import { AccordionItem, AccordionItemContent, AccordionItemTrigger, AccordionRoot } from '@/components/ui/accordion';
import NavButton from './NavButton';

export default function Submenu({
	ownerId,
	onClose,
	items,
}: {
	ownerId: string;
	onClose: () => void;
	items: { text: string; path: string }[];
}) {
	const path = usePathname();
	const n = useTranslations('Nav');
	const router = useRouter();
	const [isOpen, setIsOpen] = React.useState(false);

	return (
		<Box>
			<AccordionRoot
				collapsible
				borderRadius={8}
				backgroundColor={isOpen ? 'bg.neutralAccentAlpha' : 'transparent'}
				_hover={{
					bgColor: 'bg.neutralAccentAlpha',
				}}
				transition="0.3s ease all"
			>
				<AccordionItem value="employees" border="none">
					<AccordionItemTrigger
						onClick={() => setIsOpen(!isOpen)}
						color="fg.mediumContrast"
						fontWeight="normal"
						p={3}
						style={{
							fontSize: '16px',
							width: '100%',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
						cursor="pointer"
						height="44px"
					>
						<Flex gap={2} alignItems="center">
							<FiUsers size="20px" />
							{n('employees')}
						</Flex>
					</AccordionItemTrigger>
					<AccordionItemContent style={{ padding: 0, borderRadius: '0 0 8px 8px' }}>
						{items.map((item) => (
							<NavButton
								key={item.text}
								text={item.text}
								onClick={() => {
									router.push(`/app/owners/${ownerId}/employees${item.path}`);
									onClose();
								}}
								borderRadius="0"
								isActive={path === `/app/owners/${ownerId}/employees${item.path}`}
								hoverColor="bg.neutralAccentAlpha"
							/>
						))}
					</AccordionItemContent>
				</AccordionItem>
			</AccordionRoot>
		</Box>
	);
}
