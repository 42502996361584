import { useParams } from 'next/navigation';
import { useGetUserRoles } from '@shared/client-api';
import { useUser } from '@/supabase/AuthContext';
import { ROLES } from '@/constants/roles';

export const useIsAdminOrSuperAdmin = () => {
	const params = useParams();

	const user = useUser();

	const userRoles = useGetUserRoles(params?.ownerId as string, user?.id as string, {
		query: {
			enabled: Boolean(params?.ownerId) && Boolean(user?.id),
			staleTime: 1000 * 60 * 60,
		},
	});

	return userRoles?.data?.[0]?.role === ROLES.ADMIN || userRoles?.data?.[0]?.role === ROLES.SUPER_ADMIN;
};
