'use client';

import Link from 'next/link';
import { Box, Flex, ProgressRoot, Text } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import { setValidValue } from '@/utils/progressBars';
import { ProgressBar } from '../ui/progress';

export function SubsTrialBox(props: { ownerId: string | undefined; freeTrialEndDate?: Date | null }) {
	const b = useTranslations('Subscription.Box');
	const { ownerId, freeTrialEndDate } = props;

	const daysLeft = freeTrialEndDate
		? Math.max(0, Math.ceil((new Date(freeTrialEndDate).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)))
		: 0;

	return (
		<Link href={`/app/owners/${ownerId}/settings`}>
			<Box bg="bg.neutralAccentAlpha" borderRadius="8px" p={0}>
				<Flex flexDir="column" justifyContent="center" alignItems="center">
					<Flex p={3} pb={2} flexDir="column" gap={1} width="100%">
						<Text
							color="fg.normalContrast"
							fontSize="14px"
							fontWeight="semibold"
							textTransform="uppercase"
							lineHeight="short"
							fontFamily="Gabarito"
						>
							{b('title')}
						</Text>

						<Flex flexDir="row" justifyContent="space-between" flexWrap="wrap">
							<Text fontSize="xs">
								{b('daysLeft')} {daysLeft.toString()}
							</Text>
							<Text color="fg.primaryText" textStyle="xs" textDecor="underline">
								{b('btnLabel')}
							</Text>
						</Flex>
					</Flex>

					<ProgressRoot
						colorPalette="green"
						value={setValidValue(daysLeft, 0, 14)}
						max={14}
						maxW="sm"
						width="100%"
						aria-label="Progress"
					>
						<Flex flexDir="column" justifyContent="center" alignItems="center">
							<ProgressBar height="4px" width="100%" borderRadius={0} />
						</Flex>
					</ProgressRoot>
				</Flex>
			</Box>
		</Link>
	);
}
